// @flow
import Colors from '../../Themes/Colors';

const CaseNotesTimelineStyles = {
    addNoteButton: {
        color: Colors.white,
        fontSize: 16,
        textTransform: 'none',
        backgroundColor: Colors.buttonBackground,
        marginTop: 12,
    },
    saveNoteButton: {
        color: Colors.white,
        backgroundColor: Colors.brandPrimary,
        fontSize: 16,
        textTransform: 'none',
        marginTop: 12,
    },
    cancelNoteButton: {
        color: Colors.white,
        backgroundColor: Colors.red,
        fontSize: 16,
        textTransform: 'none',
        marginTop: 12,
    },
    disabledButton: {
        color: Colors.buttonTextDisabled,
        textTransform: 'none',
        backgroundColor: Colors.buttonBackgroundDisabled,
        marginTop: 12,
    },
    noteInputWrapper: {
        borderStyle: 'solid',
        borderColor: Colors.brandPrimary,
        borderRadius: 10,
        marginTop: 12,
        borderWidth: 1,
    },
    noteAuthorDate: {
        flexBasis: '10%',
        flexGrow: 1,
        flexShrink: 0,
        overflowWrap: 'normal',
        maxWidth: '25%',
        fontSize: 12,
        paddingLeft: 0,
    },
    noteContent: {
        fontSize: 12,
        whiteSpace: 'pre-line',
        wordWrap: 'break-word',
        maxWidth: '55%',
    },
    timelineWrapper: {
        paddingLeft: 6,
        paddingRight: 6,
    },
    buttonsWrapper: {
        display: 'flex',
        justifyContent: 'flex-start',
        paddingBottom: 12,
        gap: 20,
    },
    loadingIndicator: {
        marginTop: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    centerContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    verticalMargins: {
        marginTop: 20,
        marginBottom: 20,
    },
    caseNoteInfoText: {
        textAlign: 'left',
        fontStyle: 'italic',
        fontSize: 12,
        paddingBottom: 6,
        paddingTop: 6,
    },
    caseNoteInfoIcon: {
        paddingLeft: 6,
        paddingRight: 6,
        color: Colors.brandPrimary,
    },
};

export default CaseNotesTimelineStyles;
